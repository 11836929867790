//
// Copyright 2019-2024 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';

const LANGUAGE_EN="en";
const LANGUAGE_FR="fr";
const LANGUAGE_DE="de";
const LANGUAGE_LU="lu";

const supportedLanguages=[];
supportedLanguages.push(LANGUAGE_EN);
supportedLanguages.push(LANGUAGE_FR);
supportedLanguages.push(LANGUAGE_DE);
supportedLanguages.push(LANGUAGE_LU);


/**
 * contentHelper: fake React component providing static methods for content.
 *
 * Author: Olivier Parisot
 */
class contentHelper extends React.Component
{
  static isSupportedLang= (lang) =>
  {
    return supportedLanguages.includes(lang);
  }

  static defaultLang = () =>
  {
    return LANGUAGE_EN;
  }

  static en = () =>
  {
    return LANGUAGE_EN;
  }

  static de = () =>
  {
    return LANGUAGE_DE;
  }

  static fr = () =>
  {
    return LANGUAGE_FR;
  }

  static lu = () =>
  {
    return LANGUAGE_LU;
  }

  static appDesc = (lang) =>
  {
    if (lang===LANGUAGE_FR) return (
      <span>
      ShIFT (SeptorIa ForecasT) affiche une alerte quand le blé d'hiver a besoin d'une protection contre le champignon responsable des tâches foliaires (<i>Zymoseptoria tritici</i>).
      </span>
    );
    else if (lang===LANGUAGE_DE) return (
      <span>
      ShIFT (Septia ForecasT) zeigt einen Alarm an, wenn Winterweizen Schutz gegen den Erreger der Blattdürre (<i>Zymoseptoria tritici</i>) benötigt.
      </span>
    );
    else if (lang===LANGUAGE_EN) return (
      <span>
      ShIFT (SeptorIa ForecasT) displays an alert when winter wheat needs to be protected from the leaf blotch fungus <i>Zymoseptoria tritici</i>.
      </span>
    );
    else if (lang===LANGUAGE_LU) return (
      <span>
      ShIFT (Septia ForecasT) weist en Alarm, wann de Wanterweess Schutz géint den Erreeger vun der Blaatdrechent (Zymoseptoria tritici) braucht.
      </span>
    );

    else return <br/>;
  }

static feedbackMessage = (lang) =>
{
  if (lang===LANGUAGE_EN) return (
    "Send a comment by email"
  );
  else if (lang===LANGUAGE_FR) return (
    "Envoyer un commentaire par email"
  );
  else if (lang===LANGUAGE_DE) return (
    "Feedback per email senden"
  );
  else if (lang===LANGUAGE_LU) return (
    "Feedback per E-Mail schécken"
  );
  else return <br/>;
}


  static warningMessage = (lang,appName) =>
  {
    if (lang===LANGUAGE_EN) return (
      appName+" will be available for you again between 15 March and June."
    );
    else if (lang===LANGUAGE_FR) return (
      appName+" sera de nouveau disponible à partir du 15 mars et jusque juin."
    );
    else if (lang===LANGUAGE_DE) return (
      appName+" wird zwischen dem 15. März und Juni wieder für Sie da sein."
    );
    else if (lang===LANGUAGE_LU) return (
      appName+" kënnt tëscht dem 15. Mäerz an dem Juni fir Iech zréck."
    );
    else return <br/>;
  }

  static estimatedWithSowingDateLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "Estimated with sowing date"
    );
    else if (lang===LANGUAGE_FR) return (
      "Estimé avec la date de semis"
    );
    else if (lang===LANGUAGE_DE) return (
      "Geschätzt mit Aussaatdatum"
    );
    else if (lang===LANGUAGE_LU) return (
      "Viraussiichtlech mat Ausséidatum"
    );
    else return <br/>;
  }

  static referencesLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "References"
    );
    else if (lang===LANGUAGE_FR) return (
      "Références"
    );
    else if (lang===LANGUAGE_DE) return (
      "Referenzen"
    );
    else if (lang===LANGUAGE_LU) return (
      "Referenzen"
    );
    else return "";
  }

  static userGuideLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "User guide"
    );
    else if (lang===LANGUAGE_FR) return (
      "Guide d'utilisation"
    );
    else if (lang===LANGUAGE_DE) return (
      "Bedienungshinweise"
    );
    else if (lang===LANGUAGE_LU) return (
      "Gebrauchsuweisung"
    );
    else return "";
  }

  static disclaimerLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "Legal notice"
    );
    else if (lang===LANGUAGE_FR) return (
      "Mentions légales"
    );
    else if (lang===LANGUAGE_DE) return (
      "Rechtliche Hinweise"
    );
    else if (lang===LANGUAGE_LU) return (
      "Mentions légales"
    );
    else return "";
  }

  static privacyLabel = (lang) =>
  {
    if (lang===LANGUAGE_EN) return (
      "Privacy"
    );
    else if (lang===LANGUAGE_FR) return (
      "Données personnelles"
    );
    else if (lang===LANGUAGE_DE) return (
      "Datenschutzerklärung"
    );
    else if (lang===LANGUAGE_LU) return (
      "Données personnelles"
    );
    else return "";
  }

  static nearestStationLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Nearest weather station:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Station météo la plus proche:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Nächste Wetterstation:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Nächst Wiederstatioun:</span>
      );
      else return <br/>;
  }

  static plantGrowthStageLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Plant growth stage:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Stade de croissance:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Wachstumsstadium:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Planzewuessstuf:</span>
      );
      else return <br/>;
  }

  static plantGrowthStageAtLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Plant growth stage at:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Stade de croissance à:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Wachstumsstadium bei:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Planzewuessstuf bei:</span>
      );
      else return <br/>;
  }

  static dateOfSowingLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Date of sowing:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Date de semis:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Saattermin:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Datum wou geséint gouf:</span>
      );
      else return <br/>;
  }

  static lastFungicideSprayDateLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Last fungicide spray date:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Dernière application de fongicide:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Letzte Fungizidapplikation:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Lescht Fungizid Applikatioun:</span>
      );
      else return <br/>;
  }

  static lastFungicideSprayDateNoValue = (lang) =>
  {
    if (lang===LANGUAGE_EN)
      return 'No value'
    else if (lang===LANGUAGE_FR)
      return 'Pas de valeur'
    else if (lang===LANGUAGE_DE)
      return 'kein Wert'
    else if (lang===LANGUAGE_LU)
      return 'kee Wäert'
    else
      return 'No value';
  }

  static cultivarSusceptibilityRankLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Cultivar susceptibility rank:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Note de sensibilité de la variété:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Anfälligkeit der Sorte:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Ufällegkeet vun der Zort:</span>
      );
      else return <br/>;
  }

  static cultivarLabel = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <span>Cultivar:</span>
      );
      else if (lang===LANGUAGE_FR) return (
        <span>Variété:</span>
      );
      else if (lang===LANGUAGE_DE) return (
        <span>Sorte:</span>
      );
      else if (lang===LANGUAGE_LU) return (
        <span>Zort:</span>
      );
      else return <br/>;
  }

  static duringGrowingPeriodText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "During growing period";
      else if (lang===LANGUAGE_FR) return "Durant la phase de croissance";
      else if (lang===LANGUAGE_DE) return "Spritzung in diesen Wachstumsstadien oder Zeitraum zulässig";
      else if (lang===LANGUAGE_LU) return "Sprëtzen erlaabt";
      else return "";
  }

  static notDuringGrowingPeriodText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Not during growing period";
      else if (lang===LANGUAGE_FR) return "Hors phase de croissance";
      else if (lang===LANGUAGE_DE) return "Nicht während der Wachstumsphase";
      else if (lang===LANGUAGE_LU) return "Net während der wuessender Period";
      else return "";
  }

  static sprayForbiddenText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Spray forbidden";
      else if (lang===LANGUAGE_FR) return "Pulvérisation de fongicide interdite";
      else if (lang===LANGUAGE_DE) return "Spritzung unzulässig";
      else if (lang===LANGUAGE_LU) return "Sprëtzen verbueden";
      else return "";
  }

  static emergencyText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "EMERGENCY, need to spray before!";
      else if (lang===LANGUAGE_FR) return "URGENCE, vous devez pulveriser un fungicide avant!";
      else if (lang===LANGUAGE_DE) return "ALARM! Eine Behandlung vor Erreichen dieses Zeitraums ist ratsam.";
      else if (lang===LANGUAGE_LU) return "ALARM! Virun dësem Zäitraum sollt gesprëtzt gin.";
      else return "";
  }

  static protectedByFungicideText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Protected by fungicide";
      else if (lang===LANGUAGE_FR) return "Protégé par le fongicide";
      else if (lang===LANGUAGE_DE) return "Durch Fungizid geschützt";
      else if (lang===LANGUAGE_LU) return "Geschützt vum Fungizid";
      else return "";
  }

  static nothingToDoText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Nothing to do";
      else if (lang===LANGUAGE_FR) return "Rien à faire";
      else if (lang===LANGUAGE_DE) return "Keine Behandlung notwendig";
      else if (lang===LANGUAGE_LU) return "Keng Behandlung néideg";
      else return "";
  }

  static missingDataText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return " (Missing weather data)";
      else if (lang===LANGUAGE_FR) return " (Données météo manquantes)";
      else if (lang===LANGUAGE_DE) return " (Missing weather data)";
      else if (lang===LANGUAGE_LU) return " (Missing weather data)";
      else return "";
  }

  static wetText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "WET";
      else if (lang===LANGUAGE_FR) return "HUMIDE";
      else if (lang===LANGUAGE_DE) return "NASS";
      else if (lang===LANGUAGE_LU) return "NAASS";
      else return "";
  }

  static dryText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "DRY";
      else if (lang===LANGUAGE_FR) return "SEC";
      else if (lang===LANGUAGE_DE) return "TROCKEN";
      else if (lang===LANGUAGE_LU) return "DRECHEN";
      else return "";
  }

  static needToSprayText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Need to spray?";
      else if (lang===LANGUAGE_FR) return "Besoin de traiter? ";
      else if (lang===LANGUAGE_DE) return "Fungizidapplikation notwendig?";
      else if (lang===LANGUAGE_LU) return "Fungizidapplikatioun néideg?";
      else return "";
  }

  static temperaturesText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Temperatures";
      else if (lang===LANGUAGE_FR) return "Températures";
      else if (lang===LANGUAGE_DE) return "Temperaturen";
      else if (lang===LANGUAGE_LU) return "Temperaturen";
      else return "";
  }

  static precipitationsText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Precipitations";
      else if (lang===LANGUAGE_FR) return "Précipitations";
      else if (lang===LANGUAGE_DE) return "Niederschlagsmengen";
      else if (lang===LANGUAGE_LU) return "Wévill huet et gerent";
      else return "";
  }

  static growthStageText = (lang) =>
  {
      if (lang===LANGUAGE_EN) return "Growth stage";
      else if (lang===LANGUAGE_FR) return "Stade de croissance de la culture";
      else if (lang===LANGUAGE_DE) return "Wachstumsstadium";
      else if (lang===LANGUAGE_LU) return "Wuesstumsstadium";
      else return "";
  }

  static weatherStationDesc = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <small>
        Epidemic development is estimated using data from the closest weather station.
        </small>
      );
      else if (lang===LANGUAGE_FR) return (
        <small>
        Le développement de l’épidémie est estimé à partir des données de la station météorologique la plus proche.
        </small>
      );
      else if (lang===LANGUAGE_DE) return (
        <small>
        Der Verlauf der Epidemie wird mit Daten der nächstgelegenen Wetterstation geschätzt.
        </small>
      );
      else if (lang===LANGUAGE_LU) return (
        <small>
        De Verlaf vun der Epidemie gëtt mat den Donnée’en vun der nächster Wiederstatioun geschätzt.
        </small>
      );
      else return <br/>;
  }


  static fungicideDesc = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <small>
          Winter wheat is protected for about 22 days by a modern commercial fungicide (Greiner et al. 2019).
          During this period, your crop does not need another fungicide spray, even if infection conditions for fungi should be favourable.
        </small>
      );
      else if (lang===LANGUAGE_FR) return (
        <small>
        Le blé d’hiver est protégé pour environ 22 jours après application d’un fongicide commercial moderne (Greiner et al. 2019).
        Durant cette période, votre culture ne nécessitera pas d’application supplémentaire, même si les conditions sont favorables à l’infection par le champignon.
        </small>
      );
      else if (lang===LANGUAGE_DE) return (
        <small>
        Winterweizen wird durch ein modernes kommerzielles Fungizid 22 Tage lang geschützt (Greiner et al. 2019).
        In dieser Zeit nach einer Behandlung braucht Ihr Weizen keine erneute Spritzung, selbst wenn die Infektionsbedingungen für Pilze günstig sein sollten.
        </small>
      );
      else if (lang===LANGUAGE_LU) return (
        <small>
        Wanterweess gëtt mat engem modernen kommerziellen Fungizid 22 Deeg laang geschützt (Greiner et al. 2019).
        Wärend der Period brauch äre Weess keng wieder Sprëtzung, och wann d’Infektiounsbedéngungen fir Pilz gënschteg ass.
        </small>
      );
      else return <br/>;
  }


  static growthStageDesc = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <small>
        Plant development is predicted based on the date of sowing according to Beyer et al. (2012).
        Note that fungicide use is not permitted between the plant growth stages 31 and 65.
        BBCH plant growth stage can be entered in the neighbouring box, which improves forecast results and is recommended.
        </small>
      );
      else if (lang===LANGUAGE_FR) return (
        <small>
        Le stade de croissance de la plante est basé sur la date de semis et selon Beyer et al. (2012).
        Veuillez noter que l’utilisation de fongicide n’est autorisée qu’entre les stade de croissance 31 et 65.
        Le stade de croissance BBCH peut être indiqué dans la case dédiée, c’est recommandé car cela permet d’améliorer les prévisions.
        </small>
      );
      else if (lang===LANGUAGE_DE) return (
        <small>
        Die Pflanzenentwicklung wird anhand des Aussaattermins nach Beyer et al. (2012) vorhergesagt.
        Der Einsatz von Fungiziden ist nur zwischen den Pflanzenwachstumsstadien 31 und 65 zulässig.
        Um die Prognoseergebnisse zu verbessern wird empfohlen, das aktuelle BBCH Wachstumsstadium in das benachbarte Feld einzugegeben.
        </small>
      );
      else if (lang===LANGUAGE_LU) return (
        <small>
        D’Planzenentwécklung gëtt unhand vum Termin wou geséint gouf nom Modell vu Beyer et al. (2012) virausgesot.
        Den Asaatz vu Fungiziden ass just tëscht de Planzewuesstumsstadien 31 bis 65 erlaabt.
        Fir d’Prognoseresultater ze verbesseren gëtt recommandéiert den aktuellen BBCH Wuesstumsstadium an d’Feld niewendrun anzeginn.
        </small>
      );
      else return <br/>;
  }


  static cultivarDesc = (lang) =>
  {
      if (lang===LANGUAGE_EN) return (
        <small>
          Resistant cultivars get affected later, susceptible cultivars earlier.
          Most cultivars have a moderate resistance rank of "5".
          You can look up the correct cultivar susceptibility rank of your cultivar here:  https://sortenversuche.lu/ .
        </small>
      );
      else if (lang===LANGUAGE_FR) return (
        <small>
        Les cultivars résistants sont infectés tardivement et les cultivars sensibles plus tôt.
        La plupart des cultivars ont une note de résistance modérée de "5".
        Vous pouvez chercher la note correcte de sensibilité de votre cultivar ici :  https://sortenversuche.lu/ .
        </small>
      );
      else if (lang===LANGUAGE_DE) return (
        <small>
        Resistente Sorten werden später befallen, anfällige Sorten früher.
        Die meisten Sorten haben eine mittlere Anfälligkeit von "5".
        Sie können unter folgendem Link die Anfälligkeitsstufe Ihrer Sorte nachsehen:  https://sortenversuche.lu/ .
        </small>
      );
      else if (lang===LANGUAGE_LU) return (
        <small>
        Resistent Zorten gi méi spéit befall, ufälleg Zorten éischter.
        Déi meescht Zorten hunn eng mëttler Ufällegkeet vun 5.
        Ënnert dem heite Link kënnt der den Ufällegketsniveau vun ärer Zort nokucken:  https://sortenversuche.lu/ .
        </small>
      );
      else return <br/>;
  }


  render = () =>
  {
    return (<br/>);
  }
}

export default contentHelper;
