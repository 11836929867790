//
// Copyright 2019-2022 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';
import { ModalBody } from 'reactstrap';
import contentHelper from './contentHelper.js';


const disclaimerFR=
            (
            <ModalBody>
            <small>
            <h5>Mentions légales</h5>
            <b>Editeur du site</b>
            <br/>
            Le site a été édité par Luxembourg Institute of Science and Technology (LIST). Pour toute question à propos du site ou concernant le contenu, n’hésitez pas à nous contacter:
            <br/>
            <br/>
            Luxembourg Institute of Science and Technology
            <br/>
            Att: Communication Office
            <br/>
            5, avenue des Hauts Fourneaux
            <br/>
            L-4362 Esch-sur-Alzette
            <br/>
            Luxembourg
            <br/>
            <br/>
            RCS number: J53
            <br/>
            VAT number: LU 27327040
            <br/>
            <br/>
            Les questions relatives au projet doivent être envoyées à marco.beyer@list.lu.
            Pour toutes questions techniques ou relatives à l’accès au service, veuillez vous addresser à olivier.parisot@list.lu.
            <br/>
            <br/>
            <b>Sujet du site</b>
            <br/>
            {contentHelper.appDesc(contentHelper.fr())}
            <br/>
            <br/>
            <b>Utilisation du site</b>
            <br/>
            LIST n'assume aucune responsabilité en ce qui concerne :
            <br/>
            •	l'utilisation des informations publiées sur ce site web. Son contenu est fourni à titre indicatif seulement. Il peut ne pas être totalement complet, exhaustif ou mis à jour ;
            <br/>
            •	de tout dommage pouvant survenir sur votre installation informatique utilisant ce site web ; et
            <br/>
            •	toute interruption de service de ce site web, qu'elle soit temporaire ou permanente.
            <br/>
            Ce site web peut fournir des liens vers des sites qui ne sont pas sous le contrôle de LIST. Par conséquent, nous n'acceptons aucune responsabilité quant à leur contenu, leurs produits ou leurs services.
            <br/>
            La présente section sur la responsabilité n'a pas pour but de limiter les exigences posées par la législation en vigueur ou d'exclure la responsabilité pour des questions qui ne peuvent être exclues en vertu de la législation en vigueur.
            <br/>
            <br/>
            <b>Propriété intellectuelle</b>
            <br/>
            Ce site web (y compris tout texte, image, vidéo ou logiciel) est protégé par la propriété intellectuelle, y compris les droits d'auteur, les marques et les brevets. LIST se réserve tous ces droits.
            <br/>
            @2021, tous droits réservés.
            <br/>
            <br/>
            <b>Vie privée et cookies</b>
            <br/>
            Veuillez consulter notre Privacy Notice.
            <br/>
            <br/>
            <b>Modifications</b>
            <br/>
            LIST peut modifier cette présente notice légale à tout moment et sans préavis.
            <br/>
            <br/>
            <b>Droit applicable et jurisdiction</b>
            <br/>
            La présente notice légale et l'utilisation de ce site web sont régies par le droit luxembourgeois et tout litige s'y rapportant est soumis à la juridiction exclusive du Luxembourg.
            </small>
            </ModalBody>);

const disclaimerLU=disclaimerFR;

const disclaimerDE=
            (
            <ModalBody>
            <small>
            <h5>Rechtliche Hinweise</h5>
            <br/>
            <b>Seitenbetreiber</b>
            <br/>
            Diese Internetseite wird vom Luxembourg Institute of Science and Technology (LIST) betrieben. Bei Fragen zu dieser Website oder ihrem Inhalt wenden Sie sich bitte an uns:
            <br/>
            <br/>
            Luxembourg Institute of Science and Technology
            <br/>
            Att: Communication Office
            <br/>
            5, avenue des Hauts Fourneaux
            <br/>
            L-4362 Esch-sur-Alzette
            <br/>
            Luxembourg
            <br/>
            <br/>
            RCS nummer: J53
            <br/>
            Umsatzsteuer-Identifikationsnummer: LU 27327040
            <br/>
            <br/>
            Bei Fragen zum Projekt richten Sie bitte an marco.beyer@list.lu.
            Technische Fragen zur Websites und zur Registrierung und / oder Zugriff richten Sie bitte an olivier.parisot@list.lu.
            <br/>
            <br/>
            <b>Zweck</b>
            <br/>
            {contentHelper.appDesc(contentHelper.de())}
            <br/>
            <br/>
            <b>Nutzung dieser Internetseite</b>
            <br/>
            LIST übernimmt keine Verantwortung in Bezug auf:
            <br/>
            •	die Verwendung der auf dieser Website veröffentlichten Informationen. Der Inhalt wird nur als Anhaltspunkt angegeben. Er ist möglicherweise nicht vollständig, erschöpfend oder aktualisiert.
            <br/>
            •	alle Schäden, die bei Ihrer IT-Installation über diese Website auftreten können; und
            <br/>
            •	jede vorübergehende oder dauerhafte Unterbrechung des Dienstes dieser Website.
            <br/>
            Diese Internetseite enthält möglicherweise Links zu anderen Internetseiten, die nicht unter der Kontrolle von LIST stehen. Daher übernehmen wir keine Verantwortung für deren Inhalte, Produkte oder Dienstleistungen.
            <br/>
            Der vorliegende Haftungsausschluss soll keine im geltenden Recht festgelegten Anforderungen einschränken oder die Haftung für Angelegenheiten ausschließen, die nach geltendem Recht nicht ausgeschlossen werden dürfen.
            <br/>
            <br/>
            <b>Geistiges Eigentum</b>
            <br/>
            Diese Internetseite (einschließlich Texten, Bildern, Videos oder Software) ist durch geistiges Eigentum geschützt, einschließlich Urheberrecht, Marken und Patenten. LIST behält sich alle diese Rechte vor.
            <br/>
            @ 2021, alle Rechte vorbehalten.
            <br/>
            <br/>
            <b>Datenschutz und Cookies</b>
            <br/>
            Bitte beachten Sie unsere Datenschutzerklärung.
            <br/>
            <br/>
            <b>Änderungen</b>
            <br/>
            LIST kann diesen rechtlichen Hinweis jederzeit und ohne vorherige Ankündigung ändern.
            <br/>
            <br/>
            <b>Anwendbares Recht und Gerichtsstand</b>
            <br/>
            Dieser rechtliche Hinweis und die Nutzung dieser Internetseite unterliegen dem Recht Luxemburgs und alle damit verbundenen Rechtsstreitigkeiten unterliegen der ausschließlichen Zuständigkeit Luxemburgs. In Zweifelsfällen gilt die französische Fassung.
            </small>
            </ModalBody>);

const disclaimerEN=
            (
            <ModalBody>
            <small>
            <h5>Legal notice</h5>
            <b>Web site editor</b>
            <br/>
            This web site is edited by the Luxembourg Institute of Science and Technology (LIST). For any question concerning this web site or its content, do not hesitate to contact us:
            <br/>
            <br/>
            Luxembourg Institute of Science and Technology
            <br/>
            Att: Communication Office
            <br/>
            5, avenue des Hauts Fourneaux
            <br/>
            L-4362 Esch-sur-Alzette
            <br/>
            Luxembourg
            <br/>
            <br/>
            RCS number: J53
            <br/>
            VAT number: LU 27327040
            <br/>
            <br/>
            For questions about the project, you can address your queries to marco.beyer@list.lu.
            For technical questions about the websites and its registration and/or access, you can address your queries to olivier.parisot@list.lu.
            <br/>
            <br/>
            <b>Purpose</b>
            <br/>
            {contentHelper.appDesc(contentHelper.en())}
            <br/>
            <br/>
            <b>Use of this website</b>
            <br/>
            LIST accepts no responsibility with regard to:
            <br/>
            •	the use of the information published in this web site. Its content is provided on an indicative basis only. It may not be fully complete, exhaustive or updated;
            <br/>
            •	any and all damages which may occur on your IT installation using this web site; and
            <br/>
            •	any service interruption of this web site, whether temporary or permanent.
            <br/>
            This web site may provide links to sites that are not under LIST 's control. Therefore, we accept no responsibility with any of their content, products or services.
            <br/>
            The present liability section is not intended to limit any requirements laid down in applicable law or to exclude liability for matters which may not be excluded under applicable law.
            <br/>
            <br/>
            <b>Intellectual Property</b>
            <br/>
            This website (including any text, image, video or software) is protected by Intellectual Property, including copyright, trademarks and patents. LIST reserves all these rights.
            <br/>
            @2021, all rights reserved.
            <br/>
            <br/>
            <b>Privacy and cookies</b>
            <br/>
            Please see our privacy notice.
            <br/>
            <br/>
            <b>Modifications</b>
            <br/>
            LIST may change this legal notice at any time and without any prior notice.
            <br/>
            <br/>
            <b>Applicable law and jurisdiction</b>
            <br/>
            This legal notice and the use of this website shall be governed by the law of Luxembourg and any related litigation subject to the exclusive Luxembourg jurisdiction.
            </small>
            </ModalBody>);

const DisclaimerModalBody = ({ lang }) =>
{
  if (lang===contentHelper.en()) return (disclaimerEN);
  else if (lang===contentHelper.fr()) return (disclaimerFR);
  else if (lang===contentHelper.de()) return (disclaimerDE);
  else if (lang===contentHelper.lu()) return (disclaimerLU);
  else return <br/>;
};

export default DisclaimerModalBody;
