//
// Copyright 2019-2021 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';

import { ModalBody } from 'reactstrap';

import contentHelper from './contentHelper.js';


const bibSection=
            (
              <span>
              Beyer M., Pallez-Barthel M., Dam D., Hoffmann L., El Jarroudi M. (2022): Enhancing septoria leaf blotch forecasts in winter wheat I: the effect of temperature on the temporal distance between critical rainfall periods and the breaking of the control threshold. Journal of Plant Diseases and Protection 129: 37–44.
              &nbsp;
              <a rel="noopener noreferrer" target="_blank" href="https://doi.org/10.1007/s41348-021-00553-9">https://doi.org/10.1007/s41348-021-00553-9</a>
              <br/><br/>
              Beyer M., Marozsak B., Dam D., Parisot O., Pallez-Barthel M., Hoffmann L. (2022): Enhancing septoria leaf blotch forecasts in winter wheat II: model architecture and validation results. Journal of Plant Diseases and Protection 129: 45–51.
              &nbsp;
              <a rel="noopener noreferrer" target="_blank" href="https://doi.org/10.1007/s41348-021-00554-8">https://doi.org/10.1007/s41348-021-00554-8</a>
              <br/><br/>
              Greiner S.D., Racca P., Jung J., von Tiedemann A. (2019): Determining and modelling the effective period of fungicides against septoria leaf blotch in winter wheat. Crop Protection 117: 45-51.
              &nbsp;
              <a rel="noopener noreferrer" target="_blank" href="https://doi.org/10.1016/j.cropro.2018.11.004">https://doi.org/10.1016/j.cropro.2018.11.004</a>
              <br/><br/>
              Beyer M., El Jarroudi M., Junk J., Pogoda F., Dubos T., Görgen K., Hoffmann L. (2012): Spring air temperature accounts for the bimodal temporal distribution of Septoria tritici epidemics in the winter wheat stands of Luxembourg. Crop Protection 42: 250-255.
              &nbsp;
              <a rel="noopener noreferrer" target="_blank" href="http://dx.doi.org/10.1016/j.cropro.2012.07.015">http://dx.doi.org/10.1016/j.cropro.2012.07.015</a>
              </span>
            );

const referencesFR=
            (
              <ModalBody>
              <small>
              <h5>{contentHelper.referencesLabel(contentHelper.fr())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const referencesDE=
            (
              <ModalBody>
              <small>
              <h5>{contentHelper.referencesLabel(contentHelper.de())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const referencesEN=
            (
              <ModalBody>
              <small>
              <h5>{contentHelper.referencesLabel(contentHelper.en())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const referencesLU=
            (
              <ModalBody>
              <small>
              <h5>{contentHelper.referencesLabel(contentHelper.lu())}</h5>
              {bibSection}
              </small>
              </ModalBody>
            );

const ReferencesModalBody = ({ lang }) =>
{
  if (lang===contentHelper.en()) return (referencesEN);
  else if (lang===contentHelper.fr()) return (referencesFR);
  else if (lang===contentHelper.de()) return (referencesDE);
  else if (lang===contentHelper.lu()) return (referencesLU);
  else return <br/>;
};

export default ReferencesModalBody;
