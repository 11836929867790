//
// Copyright 2019-2021 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {Legend,Scatter,ScatterChart,XAxis,YAxis,ZAxis,Cell,Tooltip,ReferenceLine} from 'recharts';


const TimeSeriesChart = ({ mobileLF,chartData,chartHeight,chartLabel,chartColorFunction,tooltipFunction,chartUnit }) => (

    <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }} width={mobileLF?400:600} height={chartHeight} >
      <Tooltip
        formatter={(a,b,c) => { return [tooltipFunction(c.payload),moment(c.payload.time).format('D/M hh:mm')]; }}
        wrapperStyle={{backgroundColor: 'rgb(255,255,255)', opacity: 0.8}}
        contentStyle={{fontSize: 12, fontWeight: 'bold'}}
        isAnimationActive={false}
        itemStyle={{color: 'rgba(0,0,0,1)'}}
      />
      <ZAxis range={[20, 20]} />
      <XAxis
        dataKey = 'time'
        domain = {['dataMin', 'dataMax*1.1']}
        name = 'Time'
        tick={{ fontSize: '11' }}
        tickFormatter = {(unixTime) => moment(unixTime).format('Do MMM YYYY')}
        type = 'number'
      />
      <YAxis
        domain = {['dataMin', 'dataMax']}
        tick={{ fill: 'rgba(0,0,0,1)', fontSize: '11' }}
        label={{value: ""}}
        dataKey='value'
        name='Value' />
      <Legend
        height={5} wrapperStyle={{position: 'absolute', top: '0px', left: mobileLF?'0px':'-175px', fontSize: 12, fontWeight: 'bold'}}
      />
      <ReferenceLine x={moment().unix()*1000} stroke="olive" label="" strokeDasharray="3 3" />
      <Scatter
        name={chartLabel+(chartUnit!==null?" ("+chartUnit+")":"")}
        isAnimationActive = {false}
        data = {chartData}
        fill='rgba(0,0,0,0)'
        line
      >
          {
          	chartData.map((entry, index) =>
          	{
            	return <Cell key={`cell-${index}-${chartLabel}`} fill={chartColorFunction(entry)} />
            })
          }
      </Scatter>
    </ScatterChart>

)

TimeSeriesChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      value: PropTypes.number
    })
  ).isRequired
}

export default TimeSeriesChart
