//
// Copyright 2019-2021 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';

import { ModalBody } from 'reactstrap';

import contentHelper from './contentHelper.js';


const userguideFR=
            (
            <ModalBody>
            <small>
            {contentHelper.appDesc(contentHelper.fr())}
            <br/>
            <br/>
            <h5>Guide d’utilisation</h5>
            Pour obtenir les prévisions, veuillez sélectionner la position de votre parcelle sur la carte située sur la gauche après la fermeture de cette fenêtre.
            <br/>
            Puis, entrez la date de semis, la note de sensibilité de votre cultivar et la date d’application du dernier fongicide dans la partie à droite de l’écran.
            <br/>
            Si aucun fongicide n’a été pulvérisé dans la parcelle choisie pour la saison en cours, veuillez laisser le champs vide.
            <br/>
            {/*Si vous ne connaissez pas la note de sensibilité du cultivar, utilisez la valeur par défaut (5 = résistance modérée).
            <br/>*/}
            Lorsqu’une colonne de couleur rouge apparait sur le graphique sur la partie droite de l’écran, un pic d’épidémie est attendu et une application de fongicide doit être considérée avant le début de la période de couleur rouge.
            <br/>
            Vous pouvez déplacer votre curseur sur les objets de l’interface utilisateur pour obtenir plus d’informations sur chacun.
            <br/>
            <br/>
            Stades de croissance des plantes:
            &nbsp;
            <a rel="noopener noreferrer" target="_blank" href="https://www.openagrar.de/receive/openagrar_mods_00042353">https://www.openagrar.de/receive/openagrar_mods_00042353</a>
            <br/>
            Document contenant les notes de sensibilité des cultivars:
            &nbsp;
            <a rel="noopener noreferrer" target="_blank" href="https://www.sortenversuche.lu">https://www.sortenversuche.lu</a>
            </small>
            </ModalBody>
            );

const userguideDE=
            (
            <ModalBody>
            <small>
            {contentHelper.appDesc(contentHelper.de())}
            <br/>
            <br/>
            <h5>Bedienungshinweise</h5>
            Um eine Vorhersage zu erhalten, wählen Sie bitte den Standort Ihres Feldes auf der Karte links aus, nachdem Sie dieses Fenster geschlossen haben.
            <br/>
            Geben Sie dann den Saattermin, das Datum der letzten Fungizidapplikation an und die Anfälligkeitsstufe Ihrer Sorte ein.
            <br/>
            Falls in der aktuellen Saison noch kein Fungizid gespritzt wurde, lassen Sie dieses Feld offen.
            <br/>
            {/*Sollten Sie die Anfälligkeitsstufe Ihrer Sorte nicht kennen, verwenden Sie die Standardeinstellung (5=mäßig hohe Resistenz).
            <br/>*/}
            Wann immer ein roter Balken im Diagramm auf der rechten Seite angezeigt wird, muss mit einem Epidemieausbruch gerechnet werden und eine Fungizidausbringung sollte in Betracht gezogen werden, bevor der rote Balken erreicht wird.
            <br/>
            Bewegen Sie die Maus über Elemente auf der Benutzeroberfläche, um weitere Informationen zu dem entsprechenden Element zu erhalten.
            <br/>
            <br/>
            Wachstumsstadien der Pflanzen:
            &nbsp;
            <a rel="noopener noreferrer" target="_blank" href="https://www.openagrar.de/receive/openagrar_mods_00042352">https://www.openagrar.de/receive/openagrar_mods_00042352</a>
            <br/>
            Dokument, das den Empfindlichkeitsgrad der Kultur enthält:
            &nbsp;
            <a rel="noopener noreferrer" target="_blank" href="https://www.sortenversuche.lu">https://www.sortenversuche.lu</a>
            </small>
            </ModalBody>
            );

const userguideEN=
            (
              <ModalBody>
                <small>
                {contentHelper.appDesc(contentHelper.en())}
                <br/>
                <br/>
                <h5>User guide</h5>
                For taking advantage of the forecast, please select the location of your field on the map on the left hand side after closing this window.
                <br/>
                Then, enter the date of sowing, the susceptibility rank of your cultivar and the date when the last fungicide was sprayed in the fields on the right hand side.
                <br/>
                If no fungicide was sprayed in the field under consideration in the current season, leave this input screen empty.
                <br/>
                {/*If you do not know the susceptibility rank of your cultivar, use the default (5 = moderate resistance).
                <br/>*/}
                Whenever a red column is displayed in the bar chart on the right hand side, an epidemic outbreak must be expected and a fungicide spray should be considered before the red period is reached.
                <br/>
                Move the mouse across items on the user surface to get more information about them.
                <br/>
                <br/>
                Plant growth stages (in German):
                &nbsp;
                <a rel="noopener noreferrer" target="_blank" href="https://www.openagrar.de/receive/openagrar_mods_00042352">https://www.openagrar.de/receive/openagrar_mods_00042352</a>
                <br/>
                Plant growth stages (in French):
                &nbsp;
                <a rel="noopener noreferrer" target="_blank" href="https://www.openagrar.de/receive/openagrar_mods_00042353">https://www.openagrar.de/receive/openagrar_mods_00042353</a>
                <br/>
                Cultivar susceptibility ranks:
                &nbsp;
                <a rel="noopener noreferrer" target="_blank" href="https://www.sortenversuche.lu">https://www.sortenversuche.lu</a>
                </small>
              </ModalBody>
            );

const userguideLU=
            (
              <ModalBody>
                <small>
                {contentHelper.appDesc(contentHelper.lu())}
                <br/>
                <br/>
                <h5>Gebrauchsuweisung</h5>
                Fir eng Previsioun ze kréien, wielt de Standuert vun ärem Feld op der Kaart lenks aus, nodeems der dës Fënster zougemaach hutt.
                <br/>
                Duerno gitt der den Termin wou geséint gin ass, den Datum vun der leschter Fungizid-Applikatioun an de Niveau vun der Ufällegkeet vun ärer Zort an.
                <br/>
                Falls an der lafender Saison nach kee Fungizid gesprëtzt gouf, bleift dëst Feld fräi.
                <br/>
                {/*Wann der de Niveau vun der Ufällegkett vun ärer Zort net kennt, benotzt d’Grondastellung (Niveau 5, mëttler Ufällegkeet).
                <br/>*/}
                Wann eng rout Barre op der rietser Säit ugewise get ass mat engem Epidemieausbroch ze rechnen an d’Ausbréngen vu Fungiziden sollt envisagéiert gi, iert déi rout Barre erreecht get.
                <br/>
                Beweegt d’Maus iwwert d’Elementer vun der Uewerfläch fir weider Informatiounen zu dem jeeweilegen Element ze kréien.
                <br/>
                <br/>
                Wuesstumsstadien vun de Planzen (op Däitsch):
                &nbsp;
                <a rel="noopener noreferrer" target="_blank" href="https://www.openagrar.de/receive/openagrar_mods_00042352">https://www.openagrar.de/receive/openagrar_mods_00042352</a>
                <br/>
                Dokument daat d’Empfindlechkeet vun der Kultur weist:
                &nbsp;
                <a rel="noopener noreferrer" target="_blank" href="https://www.sortenversuche.lu">https://www.sortenversuche.lu</a>
                </small>
              </ModalBody>
            );

const UserGuideModalBody = ({ lang }) =>
{
  if (lang===contentHelper.en()) return (userguideEN);
  else if (lang===contentHelper.fr()) return (userguideFR);
  else if (lang===contentHelper.de()) return (userguideDE);
  else if (lang===contentHelper.lu()) return (userguideLU);
  else return <br/>;
};

export default UserGuideModalBody;
