//
// Copyright 2021-2023 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';
import { ModalBody } from 'reactstrap';
import contentHelper from './contentHelper.js';


const privacyFR=
            (
            <ModalBody>
            <small>
            <h5>Données personnelles</h5>
            <br/>
            La présente notice s'adresse aux utilisateurs ou visiteurs du présent site web (ci-après le "site web") et aux personnes qui nous contactent par tout moyen en relation avec le site web (ci-après ensemble "vous").
            Le contrôleur des données est Luxembourg Institute of Science and Technology (hereafter “LIST”, “Nous”), dont les locaux sont situés à 5, Avenue des Hauts-Fourneaux L-4362 Esch-sur-Alzette, Luxembourg.
            Le LIST est responsable de la collecte et du traitement de vos données personnelles via ce site web.
            <br/>
            Le LIST collecte et traite les données personnelles conformément à la législation nationale et européenne en matière de protection des données et notamment au règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données et abrogeant la directive 95/46/CE (ci-après "GDPR").
            A travers ce site web, le LIST recueille les données suivantes à votre sujet :
            -	Vos données de localisation, que vous fournissez lorsque vous utilisez la carte sur le site web afin d'obtenir des données météorologiques de la station météorologique la plus proche de chez vous,
            <br/>
            -	Votre adresse IP, qui est automatiquement collectée par nos systèmes lorsque vous vous inscrivez et/ou accédez à ce site web et qui est stockée pour des raisons techniques et de sécurité,
            <br/>
            -	Vos coordonnées (telles que l'e-mail, le nom, le prénom), lorsque vous nous contactez pour quelque raison que ce soit.
            <br/>
            <br/>
            Nous ne traitons vos données que pour les finalités mentionnées ci-dessus.
            Nous traitons vos données en fonction de notre intérêt légitime, qui consiste à vous fournir des données météorologiques, à assurer le bon fonctionnement de notre site web ou à traiter vos demandes.
            Vos données de localisation sont immédiatement supprimées après la clôture de votre session en cours et elles ne sont pas stockées sur nos serveurs.
            Votre adresse IP est conservée pendant une période maximale de six (6) mois pour des raisons techniques et de sécurité.
            Vos coordonnées ne sont conservées que pendant la période strictement nécessaire aux fins pour lesquelles vos données sont collectées, pour respecter une obligation légale ou réglementaire à laquelle LIST est soumise ou pour établir et/ou défendre nos droits légaux.
            <br/>
            <br/>
            Nous ne partageons vos données personnelles avec aucune partie externe ni avec d'autres services internes du LIST. Nous veillons à mettre en œuvre les mesures techniques et organisationnelles appropriées afin d'empêcher ou d'agir sur tout traitement ou divulgation non autorisé et illégal, perte accidentelle, modification ou destruction de données personnelles. Ces mesures sont mises en œuvre sur la base de l'état actuel de la technique, d'une évaluation des risques découlant de l'activité de traitement et de la nécessité de protéger les données à caractère personnel.
            En ce qui concerne vos données personnelles, vous avez le droit d'y accéder, de les modifier, de restreindre leur traitement ou de les supprimer, lorsque leur traitement n'est plus nécessaire ou légal. Vous pouvez exercer vos droits en envoyant un courrier électronique au DPD de LIST à l'adresse suivante : dpo@list.lu.
            <br/>
            Enfin, vous avez également le droit de déposer une plainte auprès de la Commission nationale pour la protection des données (CNPD). Vous trouverez de plus amples informations sur la manière de déposer une plainte sur le site web de la CNPD : https://cnpd.public.lu.
            <br/>
            <br/>
            <b>Cookies</b>
            <br/>
            Ce site web utilise des cookies. Les cookies sont de petits fichiers texte qui sont envoyés et stockés sur votre ordinateur personnel ou votre appareil lorsque vous visitez un site web. Les informations stockées peuvent être renvoyées aux serveurs du site web ou aux serveurs des tiers concernés lors d'une visite ultérieure.
            Sur ce site web, nous n'utilisons que les cookies essentiels qui sont nécessaires au bon fonctionnement du site.
            </small>
            </ModalBody>);

const privacyLU=privacyFR;

const privacyDE=
            (
            <ModalBody>
            <small>
            <h5>Datenschutzerklärung</h5>
            Die vorliegende Mitteilung richtet sich an Benutzer oder Besucher der vorliegenden Internetseite (im Folgenden „Internetseite“) und an Personen, die uns in irgendeiner Weise in Bezug auf die Internetseite kontaktieren (im Folgenden zusammen „Sie“).
            Der für die Datenverarbeitung Verantwortliche ist das Luxembourg Institute of Science and Technology (im Folgenden „LIST“, „Wir“) mit Sitz in 5, Avenue des Hauts-Fourneaux L-4362 Esch-sur-Alzette, Luxemburg.
            LIST ist verantwortlich für die Erhebung und Verarbeitung Ihrer persönlichen Daten über diese Internetseite.
            LIST erhebt und verarbeitet personenbezogene Daten gemäß den nationalen und europäischen Datenschutzgesetzen und insbesondere gemäß der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen im Hinblick auf die Verarbeitung personenbezogener Daten und des freien Datenverkehrs sowie Aufhebung der Richtlinie 95/46 / EG (im Folgenden „DSGVO“).
            <br/>
            Über die Internetseite sammelt LIST die folgenden Daten über Sie:
            <br/>
            •	Ihre Standortdaten, die Sie bei Verwendung der Karte auf der Internetseite angeben, um Wetterdaten von der nächstgelegenen Wetterstation in Ihrer Nähe abzurufen.
            <br/>
            •	Ihre IP-Adresse, die von unseren Systemen automatisch erfasst wird, wenn Sie sich registrieren und / oder auf diese Internetseite zugreifen, und aus Sicherheits- und technischen Gründen gespeichert wird.
            <br/>
            •	Ihre Kontaktdaten (wie E-Mail-Adresse, Name, Nachname), wenn Sie uns zu irgendeinem Zweck kontaktieren.
            <br/>
            <br/>
            Wir verarbeiten Ihre Daten nur zu den oben genannten Zwecken.
            Wir verarbeiten Ihre Daten auf der Grundlage unseres berechtigten Interesses, das darin besteht, Ihnen Wetterdaten zur Verfügung zu stellen, das ordnungsgemäße Funktionieren unserer Internetseite sicherzustellen oder Ihre Anfragen zu bearbeiten.
            Ihre Standortdaten werden sofort nach dem Schließen Ihrer aktuellen Sitzung gelöscht und nicht auf unseren Servern gespeichert.
            Ihre IP-Adresse wird aus technischen und Sicherheitsgründen maximal sechs (6) Monate gespeichert. Ihre Kontaktdaten werden nur für einen Zeitraum gespeichert, der für die Zwecke, für die Ihre Daten erhoben werden, unbedingt erforderlich ist, um einer gesetzlichen oder behördlichen Verpflichtung nachzukommen, der LIST unterliegt, oder um unsere gesetzlichen Rechte zu begründen und / oder zu verteidigen.
            Wir geben Ihre personenbezogenen Daten weder an Dritte noch an andere interne Abteilungen von LIST weiter. Wir stellen sicher, dass geeignete technische und organisatorische Maßnahmen getroffen werden, um unbefugte und rechtswidrige Verarbeitung oder Offenlegung, versehentlichen Verlust, Änderung oder Zerstörung personenbezogener Daten zu verhindern. Diese Maßnahmen werden auf der Grundlage des aktuellen Standes der Technik, einer Bewertung der Risiken, die sich aus der Verarbeitungstätigkeit ergeben, und der Notwendigkeit, personenbezogene Daten zu schützen, umgesetzt.
            In Bezug auf Ihre Kontaktdaten haben Sie das Recht, auf diese zuzugreifen, sie zu ändern, einzuschränken oder zu löschen, wenn deren Verarbeitung nicht mehr erforderlich oder rechtmäßig ist. Sie können Ihre Rechte ausüben, indem Sie eine E-Mail an den Datenschutzbeauftragten von LIST unter der folgenden Adresse senden: dpo@list.lu.
            Schließlich haben Sie auch das Recht, eine Beschwerde bei der Commission Nationale pour la Protection des Données (CNPD) einzureichen. Weitere Informationen zum Einreichen einer Beschwerde finden Sie auf der CNPD-Internetseite: https://cnpd.public.lu.
            <br/>
            <br/>
            <b>Cookies</b>
            <br/>
            Diese Internetseite verwendet Cookies. Cookies sind kleine Textdateien, die beim Besuch einer Internetseite an Ihren PC oder Ihr Gerät gesendet und dort gespeichert werden. Die gespeicherten Informationen können bei einem späteren Besuch an die Server der Internetseite oder an die Server der betreffenden Dritten zurückgegeben werden.
            Auf dieser Internetseite verwenden wir nur wichtige Cookies, die für das ordnungsgemäße Funktionieren der Internetseite erforderlich sind.
            Im Zweifelfall gilt die französische Version der Datenschutzerklärung.
            </small>
            </ModalBody>);

const privacyEN=
            (
            <ModalBody>
            <small>
            <h5>Privacy notice</h5>
            <br/>
            The present notice is directed to users or visitors of the present website (hereafter the “website”) and to individuals who contact us by any means in relation to the website (hereafter together “you”).
            The data controller is the Luxembourg Institute of Science and Technology (hereafter “LIST”, “We”), having its registered office at 5, Avenue des Hauts-Fourneaux L-4362 Esch-sur-Alzette, Luxembourg.
            LIST is responsible for collecting and processing your personal data via this website.
            <br/>
            <br/>
            LIST collects and processes personal data in accordance with the national and European data protection legislation and in particular with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (hereafter “GDPR”).
            Via the website, LIST collects the following data about you:
            <br/>
            •	Your location data, that you provide when using the map on the website in order to obtain weather data from the nearest weather station around you,
            <br/>
            •	Your IP address, that is automatically collected by our systems when you register and/or access this website and stored for security and technical reasons,
            <br/>
            •	Your contact details (such as e-mail address, name, surname), when you contact us for any purpose.
            <br/>
            <br/>
            We only process your data for the purposes mentioned here above.
            We process your data based on our legitimate interest, that consists of providing you with weather data , ensuring the proper functioning of our website or dealing with your requests.
            Your location data is immediately deleted after you close your current session and it not stored on our servers.
            Your IP address is stored for a maximum period of six (6) months for technical and security reasons. Your contact details are only retained for a period of time that is strictly necessary for the purposes for which your data are collected, to comply with a legal or regulatory obligation to which LIST is subject or to establish and/or defend our legal rights.
            We do not share your personal data with any external party nor with other internal departments at LIST.
            We make sure to implement appropriate technical and organisational measures in order to prevent or act upon any unauthorised and unlawful processing or disclosure, accidental loss, modification or destruction of personal data. These measures are implemented based on the current state of art, an evaluation of the risks derived by the processing activity and the need to protect personal data.
            In relation to your contact details, you have the right to access, modify, restrict or delete them, when the processing thereof is no longer necessary or lawful. You can exercise your rights by sending an e-mail to the DPO of LIST at the following address: dpo@list.lu.
            Finally, you also have the right to lodge a complaint with the Commission Nationale pour la Protection des Données (CNPD). More information on how to lodge a complaint are available on CNPD’s website: https://cnpd.public.lu.
            <br/>
            <br/>
            <b>Cookies</b>
            <br/>
            This website uses cookies. Cookies are small text files that are sent and stored to your personal computer or device when you visit a website. The information stored may be returned to the website’s servers or to the servers of the relevant third parties during a subsequent visit.
            On this website, we only use essential cookies that are required for the proper functioning of the website.
            </small>
            </ModalBody>);



const PrivacyModalBody = ({ lang }) =>
{
  if (lang===contentHelper.en()) return (privacyEN);
  else if (lang===contentHelper.fr()) return (privacyFR);
  else if (lang===contentHelper.de()) return (privacyDE);
  else if (lang===contentHelper.lu()) return (privacyLU);
  else return <br/>;
};

export default PrivacyModalBody;
