//
// Copyright 2019-2021 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {Bar,BarChart,XAxis,YAxis,Cell,Tooltip,Legend,ReferenceLine} from 'recharts';

import shiftHelper from './shiftHelper.js';


const Histogram = ({ mobileLF,chartData,chartHeight,chartLabel,chartColorFunction,tooltipFunction }) => (

    <BarChart
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          cx={610}
          cy={chartHeight}
          width={mobileLF?400:600}
          height={chartHeight}
          data={chartData}
          barGap={0}
          barCategoryGap={0} >
      <Tooltip
        formatter={(a,b,c) =>
            {
              //return [tooltipFunction(c.payload),
                        //moment(c.payload.time).format('D/M')+"-"+moment(c.payload.endTime).format('D/M')];
              return [tooltipFunction(c.payload)];

            }
        }
        labelFormatter={(v)=>
                        {
                          const tsMin=moment(v);
                          const tsMax=moment(v).add(shiftHelper.hoursBarWidth,'hours');
                          return tsMin.format('D/M')+"-"+tsMax.format('D/M');
                        }}
        wrapperStyle={{backgroundColor: 'rgb(255,255,255)', opacity: 0.8}}
        contentStyle={{fontSize: 12, fontWeight: 'bold'}}
        isAnimationActive={false}
        itemStyle={{color: 'rgba(0,0,0,1)'}}
      />
      <XAxis
        dataKey='time'
        domain={['dataMin', 'dataMax']}
        name='Time'
        tick={{ fontSize: '11' }}
        tickFormatter={(unixTime) => moment(unixTime).format('Do MMM YYYY')}
        type='number'
      />
      <YAxis
        domain={['dataMin', 'dataMax']}
        tick={{ fill: 'rgba(0,0,0,1)', fontSize: '11' }}
        label={{value: ""}}
        dataKey='value'
        name='Value'
      />
      <Legend
        height={5}
        wrapperStyle={{position: 'absolute', top: '0px', left: mobileLF?'0px':'-175px', fontSize: 12, fontWeight: 'bold'}}
      />
      <ReferenceLine x={moment().unix()*1000} stroke="olive" label="" strokeDasharray="3 3" />
      <Bar
        isAnimationActive={false}
        dataKey="value"
        name={chartLabel}
        fill='rgba(0,0,0,0)'
      >
       {
        chartData.map((entry,index) =>
        {
          return <Cell key={`cell-${index}-${chartLabel}`} fill={chartColorFunction(entry)} />
        })
       }
      </Bar>
    </BarChart>
)

Histogram.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.number,
      value: PropTypes.number
    })
  ).isRequired
}

export default Histogram
